<template>
  <div>
    <v-menu
      v-model="dateMenu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
      class="overlay"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          label="Fecha"
          :value="dateFormatted"
          :hint="formattedToLetters"
          @click:append="dateMenu = !dateMenu"
          append-icon="mdi-calendar"
          persistent-hint
          readonly
          outlined
          dense
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        locale="es-ES"
        color="primary"
        @input="dateMenu = false"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "to",
  data: () => ({
    date: moment().startOf("day").toDate().toJSON().slice(0, 10),
    dateMenu: false,
  }),
  computed: {
    dateFormatted() {
      return this.date ? moment(this.date).format("DD/MM/YYYY") : "";
    },
    formattedToLetters() {
      const date = this.date
        ? moment(this.date).locale("es").format("LLLL")
        : "";
      return date !== "" ? date.substring(0, date.length - 4) : date;
    },
  },
  watch: {
    date(value) {
      this.$emit("filter", value);
    },
  },
};
</script>

<style scoped>
.overlay {
  z-index: 100;
}
</style>
