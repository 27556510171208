<template>
  <v-container class="pa-md-5" fluid>
    <v-row>
      <v-col cols="12">
        <h2 class="secondary--text">Agenda</h2>
        <v-divider class="mt-2"></v-divider>
        <div class="mb-8 d-flex">
          <v-tabs
            v-model="display"
            icons-and-text
            height="40"
            color="secondary"
            slider-color="secondary"
          >
            <v-tab>
              <div class="caption font-weight-medium d-flex align-center">
                <div>Hoy</div>
              </div>
            </v-tab>
            <v-tab>
              <div class="caption font-weight-medium d-flex align-center">
                <div>Fecha</div>
              </div>
            </v-tab>
          </v-tabs>
        </div>
        <div class="d-md-flex align-start justify-space-between">
          <div
            class="d-md-flex align-center"
            v-if="appointmentsSelected.length === 0"
          >
            <div v-show="display === 1" class="mr-5">
              <DateFilter @filter="handleFilterByDate" />
            </div>

            <div class="d-flex align-center mb-6">
              <v-btn
                depressed
                small
                title="Agregar un paciente a la agenda"
                color="primary"
                class="mr-3"
                :disabled="locations.length === 0"
                @click="handleClickAdd"
              >
                Agregar
              </v-btn>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    icon
                    title="Refrescar datos"
                    @click="refreshData"
                    :disabled="loadingAppointments"
                  >
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span class="caption">Refrescar datos</span>
              </v-tooltip>
            </div>
          </div>
          <div class="d-md-flex align-center mb-6" v-else>
            <v-btn
              depressed
              small
              title="Solicitar confirmación de asistencia"
              class="mr-3 mb-md-0 mb-4"
              @click="showRequestMultipleConfirmation = true"
            >
              Enviar recordatorio
            </v-btn>
            <v-btn
              depressed
              small
              title="Cambiar de fecha"
              class="mr-3"
              @click="handleClickUpdateMultiple"
            >
              Cambiar fecha
            </v-btn>
            <v-btn
              depressed
              small
              title="Eliminar múltiples registros"
              color="error"
              class="mr-3"
              @click="showWarningDeleteMultiple"
            >
              Eliminar
            </v-btn>
          </div>
          <div v-show="display === 0" class="mb-6">
            <AppointmentInProgress
              ref="appointmentInProgressRef"
              :updateAppointmentList="updateAppointmentList"
              :showStartBtn="todayAppointments[0] ? true : false"
              :showPatientBtn="true"
              @click-cobrar="setAppointmentToPay"
            />
          </div>
        </div>

        <!-- table -->
        <v-row>
          <v-col>
            <AppointmentWaitingList
              ref="appointmentsList"
              :headers="headers"
              :list="display === 0 ? todayAppointments : appointments"
              :loading="loadingAppointments"
              @clickEditRow="handleClickEdit"
              @row-selected="handleRowSelected"
              @appointment-deleted="updateAppointmentList"
              @position-change="handlePositionChange"
            />
          </v-col>
        </v-row>
        <!-- table -->

        <AppointmentForm
          ref="appointmentForm"
          :removeWhenDateChange="true"
          @created="handleAppointmentCreated"
          @date-updated="refreshData"
          @location-updated="refreshData"
        />

        <WarningDialog
          ref="WarningDialogRef"
          :loading="deletingMultiple"
          @action="deleteMultipleAppointments"
        />

        <v-dialog
          width="800"
          v-model="showUpdateMultipleForm"
          @keydown.esc="showUpdateMultipleForm = false"
          persistent
        >
          <v-card class="pa-sm-10 pa-5">
            <div class="text-right">
              <v-icon
                @click="showUpdateMultipleForm = false"
                style="cursor: pointer"
              >
                mdi-close
              </v-icon>
            </div>
            <AppointmentUpdateMultiple
              ref="AppointmentUpdateMultipleRef"
              @cancel="showUpdateMultipleForm = false"
              @success="
                appointmentsSelected = [];
                showUpdateMultipleForm = false;
                refreshData();
              "
            />
          </v-card>
        </v-dialog>

        <v-dialog
          width="600"
          v-model="showRequestMultipleConfirmation"
          @keydown.esc="showRequestMultipleConfirmation = false"
          persistent
        >
          <v-card class="pa-sm-10 pa-5">
            <div class="text-right">
              <v-icon
                @click="showRequestMultipleConfirmation = false"
                style="cursor: pointer"
              >
                mdi-close
              </v-icon>
            </div>
            <Title :title="'Enviar recordatorio'" />
            <v-row class="mt-1">
              <v-col cols="12">
                Envía un correo electrónico a los pacientes para recordarles su
                cita agendada. Estos podrán confirmar su asistencia.
              </v-col>
            </v-row>
            <div class="d-flex mt-5">
              <v-btn
                small
                depressed
                color="primary"
                class="pa-5"
                :loading="sendingConfirmationMail"
                @click="handleClickRequestConfirmation"
              >
                Enviar
              </v-btn>
              <v-btn
                small
                text
                color="primary"
                class="pa-5 ml-3"
                @click="showRequestMultipleConfirmation = false"
              >
                Cancelar
              </v-btn>
            </div>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Patient from "@/components/Patient/Patient";
import AppointmentWaitingList from "@/components/Appointment/AppointmentWaitingList";
import AppointmentForm from "@/components/Appointment/AppointmentForm.vue";
import AppointmentUpdateMultiple from "@/components/Appointment/AppointmentUpdateMultiple.vue";
import WarningDialog from "@/components/WarningDialog.vue";
import DateFilter from "@/components/DateFilter.vue";
import AppointmentInProgress from "@/components/Appointment/AppointmentInProgress.vue";
import axios from "axios";
import Title from "../components/Title.vue";

export default {
  name: "AppointmentsManagement",
  components: {
    AppointmentForm,
    AppointmentUpdateMultiple,
    AppointmentWaitingList,
    Patient,
    WarningDialog,
    DateFilter,
    AppointmentInProgress,
    Title,
  },
  data() {
    return {
      headers: [
        { text: "#", value: "position", align: "center" },
        { text: "Código", value: "code", align: "center" },
        { text: "Paciente", value: "patient" },
        { text: "Motivo", value: "reason" },
        { text: "Hora estimada", value: "estimatedTime" },
        { text: "Confirmación", value: "confirmation" },
        { text: "Estado de pago", value: "isPaid", align: "center" },
        { text: "", value: "action", align: "center" },
      ],
      showUpdateMultipleForm: false,
      showRequestMultipleConfirmation: false,
      deletingMultiple: false,
      sendingConfirmationMail: false,
      appointmentsSelected: [],
      appoinmentDate: "",
      today: "",
      display: 0,
      firstLoading: true,
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "attending",
      "appointments",
      "todayAppointments",
      "doctorForms",
      "locations",
      "currentLocation",
      "loadingAppointments",
    ]),
    dateSelected() {
      if (this.display === 0) {
        return this.today;
      }

      return this.appoinmentDate;
    },
  },
  methods: {
    ...mapActions(["fetchAppointmentsByDoctor"]),
    ...mapMutations([
      "setLoadingTurns",
      "setDate",
      "setNextTurnWarning",
      "setFromDate",
      "setAlert",
      "setAppointments",
      "setTableSelected",
      "setTodayAppointments",
      "setWaitingDateSelected",
      "pushAppointment",
      "pushTodayAppointment",
    ]),
    handlePositionChange({ list }) {
      this.setNewAppointmentList({ newAppointmentList: list });
    },
    async handleFilterByDate(date) {
      this.appoinmentDate = date;
      this.fetchAppointments();
    },
    handleAppointmentCreated() {
      this.refreshData();
    },
    async refreshData() {
      this.fetchAppointments();
      this.fetchTodayAppointments();
    },
    showWarningDeleteMultiple() {
      const appointmentInProgress = this.appointmentsSelected.filter(
        (appointment) => appointment.id === this.attending?.id
      )[0];
      if (appointmentInProgress) {
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: "No puedes eliminar una consulta que se encuentra en progreso",
        });
        return;
      }

      const count = this.appointmentsSelected.length;
      const itemName = count === 1 ? " registro" : " registros";
      this.$refs.WarningDialogRef.description = `¿Seguro que desea eliminar ${
        count + itemName
      }?`;
      this.$refs.WarningDialogRef.show = true;
    },
    async deleteMultipleAppointments() {
      try {
        this.deletingMultiple = true;

        const haveAttendingAppointment = this.appointmentsSelected.filter(
          (appointment) => appointment.id === this.attending
        )[0];
        if (haveAttendingAppointment) {
          this.deletingMultiple = false;
          this.setAlert({
            show: true,
            color: "error",
            icon: "mdi-alert-remove",
            text: "No puede cambiarle la fecha a una consulta en progreso...",
          });
          return false;
        }

        const res = await axios.delete(`/api/appointments/`, {
          data: { appointments: this.appointmentsSelected },
        });
        const { message, appointments } = res.data;

        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });

        this.setNewAppointmentList({ newAppointmentList: appointments });

        this.$refs.WarningDialogRef.show = false;
        this.$refs.appointmentsList.selected = [];
        this.appointmentsSelected = [];
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error?.response?.data?.message,
        });
      } finally {
        this.deletingMultiple = false;
      }
    },
    handleClickAdd() {
      this.$refs.appointmentForm.action = "create";
      this.$refs.appointmentForm.time = null;
      this.$refs.appointmentForm.form = {
        patient: null,
        reason: "",
        date: this.dateSelected,
        estimatedTime: "",
      };
      this.$refs.appointmentForm.choosePatient = true;
    },
    async handleClickRequestConfirmation() {
      try {
        this.sendingConfirmationMail = true;

        const res = await axios.post(`/api/appointments/confirmation`, {
          appointmentsId: this.appointmentsSelected.map((a) => a.id),
          doctorId: this.currentUser.doctor.id,
        });
        const { message } = res.data;

        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });

        this.showRequestMultipleConfirmation = false;
        this.$refs.appointmentsList.selected = [];
        this.appointmentsSelected = [];
        this.refreshData();
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error?.response?.data?.message,
        });
      } finally {
        this.sendingConfirmationMail = false;
      }
    },
    handleClickUpdateMultiple() {
      const appointmentInProgress = this.appointmentsSelected.filter(
        (appointment) => appointment.id === this.attending?.id
      )[0];
      if (appointmentInProgress) {
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: "No puedes modificar una consulta que se encuentra en progreso",
        });
        return;
      }

      this.showUpdateMultipleForm = true;
      this.$nextTick(() => {
        this.$refs.AppointmentUpdateMultipleRef.currentDate =
          this.appoinmentDate;
        this.$refs.AppointmentUpdateMultipleRef.form.date = this.appoinmentDate;
        this.$refs.AppointmentUpdateMultipleRef.appointments =
          this.appointmentsSelected;
      });
    },
    handleClickEdit(item) {
      const appointmentByEstimatedTime =
        item.estimatedTime && item.estimatedTime !== "Sin definir";
      this.$refs.appointmentForm.time = appointmentByEstimatedTime
        ? moment(item.estimatedTime, "hh:mm A").format("HH:mm")
        : null;

      this.$refs.appointmentForm.action = "update";
      this.$refs.appointmentForm.form = { ...item };
      this.$refs.appointmentForm.currentDate = item.date;
      this.$refs.appointmentForm.appointmentDialog = true;
    },
    updateAppointmentList({ newAppointmentList, appointment }) {
      const locationChanged =
        this.currentLocation?.id !== appointment?.location?.id;

      if (
        moment(appointment.date).format("YYYY-MM-DD") === this.today &&
        !locationChanged
      ) {
        this.setTodayAppointments(newAppointmentList);
      }

      if (
        moment(appointment.date).format("YYYY-MM-DD") === this.appoinmentDate &&
        !locationChanged
      ) {
        this.setAppointments(newAppointmentList);
      }
    },
    setNewAppointmentList({ newAppointmentList }) {
      if (
        this.dateSelected === this.today &&
        this.appoinmentDate === this.today
      ) {
        this.setTodayAppointments(newAppointmentList);
        this.setAppointments(newAppointmentList);
      } else if (this.dateSelected === this.today) {
        this.setTodayAppointments(newAppointmentList);
      } else {
        this.setAppointments(newAppointmentList);
      }
    },
    handleRowSelected(rows) {
      this.appointmentsSelected = rows;
      this.setTableSelected(rows);
    },
    setAppointmentToPay(apointment) {
      this.$refs.appointmentsList.handleClickPayment(apointment, "create");
    },
    async fetchAppointments() {
      const data = await this.fetchAppointmentsByDoctor({
        doctorId: this.currentUser.doctor.id,
        dateFrom: this.appoinmentDate,
        dateTo: this.appoinmentDate,
        locationId: this.currentLocation?.id,
        status: "en espera",
      });

      const { items } = data;
      this.setAppointments(items);

      return data;
    },
    async fetchTodayAppointments() {
      const data = await this.fetchAppointmentsByDoctor({
        doctorId: this.currentUser.doctor.id,
        dateFrom: this.today,
        dateTo: this.today,
        locationId: this.currentLocation?.id,
        status: "en espera",
      });

      const { items } = data;
      this.setTodayAppointments(items);

      return data;
    },
  },
  watch: {
    display: function () {
      this.$refs.appointmentsList.resetRowsSelected();
    },
    dateSelected: function (val) {
      this.setWaitingDateSelected(val);
    },
    currentLocation: function (val) {
      if (!this.firstLoading) {
        this.refreshData();
      }
    },
  },
  async mounted() {
    // fetch appointments for today by default
    this.today = moment().startOf("day").toDate().toJSON().slice(0, 10);
    this.appoinmentDate = this.today;

    const data = await this.fetchAppointments();
    const { items } = data;
    this.firstLoading = false;

    this.setAppointments(items);
    this.setTodayAppointments(items);
  },
};
</script>
