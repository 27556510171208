<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          :color="chip.color"
          :text-color="chip.color ? 'white' : ''"
          small
          v-bind="attrs"
          v-on="on"
        >
          {{chip.text}}
        </v-chip>
      </template>
      <span class="caption">{{ chip.tooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "AppointmentConfirmationBanner",
  props: ["confirmation"],
  computed: {
    chip() {
      if (this.confirmation == "pendiente") {
        return {
          color: "",
          text: "Pendiente",
          tooltip: "Confirmación pendiente",
        };
      }

      if (this.confirmation == "confirmado") {
        return {
          color: "primary",
          text: "Confirmada",
          tooltip: "Asistencia confirmada",
        };
      }

      if (this.confirmation == "cancelada") {
        return {
          color: "error",
          text: "Cancelada",
          tooltip: "Asistencia cancelada",
        };
      }
    },
  },
};
</script>
