<template>
  <v-form ref="form" @submit.prevent="editAppointments()">
    <v-container class="mb-5">
      <Title :title="title" />
      <v-row>
        <v-col cols="12" md="12" class="d-flex">
          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Fecha"
                readonly
                outlined
                :value="formattedDate"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.date"
              locale="es-ES"
              color="primary"
              @input="dateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-container>
    <v-btn
      small
      depressed
      color="primary"
      class="pa-5"
      type="submit"
      :loading="loading"
    >
      Actualizar
    </v-btn>
  </v-form>
</template>

<script>
import axios from "axios";
import { mapMutations, mapGetters } from "vuex";
import moment from "moment";
import Title from "../Title.vue";

export default {
  name: "AppointmentUpdateMultiple",
  components: {
    Title,
  },
  data() {
    return {
      form: {
        date: "",
      },
      appointments: [],
      dateMenu: false,
      loading: false,
      currentDate: "",
    };
  },
  computed: {
    ...mapGetters(["attending"]),
    title() {
      return this.appointments.length === 1
        ? "Actualizar 1 registro"
        : `Actualizar ${this.appointments.length} registros`;
    },
    formattedDate() {
      return this.form.date
        ? moment(this.form.date).format("DD/MM/YYYY")
        : moment().format("DD/MM/YYYY");
    },
  },
  methods: {
    ...mapMutations(["setAlert", "removeAppointment"]),
    async editAppointments() {
      try {
        this.loading = true;

        const haveAttendingAppointment = this.appointments.filter(
          (appointment) => appointment.id === this.attending
        )[0];
        if (haveAttendingAppointment) {
          this.loading = false;
          this.setAlert({
            show: true,
            color: "error",
            icon: "mdi-alert-remove",
            text: "No puede cambiarle la fecha a una consulta en progreso...",
          });
          return false;
        }

        const res = await axios.put(`/api/appointments/`, {
          date: this.form.date,
          appointments: this.appointments,
        });

        const { message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });

        this.$emit("success");
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error?.response?.data?.message,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
